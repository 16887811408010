import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

const OmPage = () => (
    <Layout>
        <SEO title="Om" />
        <div className="mainWrapper">
            <h1>Om oss</h1>
            <h3>Himlatak – mer än bara Utsiktsgaranti</h3>
            <p>Himlatak kan erbjuda en blå himmel året runt eller utsikt över en bergstopp. Detta är vad vi kallar Utsiktsgaranti, men för Himlatak är Utsiktsgaranti inte bara en term vi använder för att beskriva hur ditt tak eller din vägg kan se ut. För oss innebär Utsiktsgaranti den känsla av harmoni, energi och koppling till naturen som våra produkter bidrar med.</p>
            <div className="imgSection">
                <div className="sectionWrapper">
                    <p>

                        Naturligt ljus och koppling till naturen har visat sig ha mycket goda effekter på människans välbefinnande samt vår förmåga att prestera.Med vår passion för ljus och kvalitet vill vi öka möjligheten till naturligt ljus och känsla till naturen i inomhusmiljöer.
                        <br></br>
                        Fenomenet där människans välbefinnande och möjlighet att prestera ökar som följd av minskad stress i koppling till naturen kallas Biophilia.Forskare tror att fenomenet grundar sig i att vår hjärna har en stark koppling till naturen.
                        <br></br>

                        I vår butik har du möjlighet att välja motiv och färg på ditt tak eller till och med skapa ditt egna motiv tillsammans med någon av våra proffsfotografer.Nyfiken på vad Himlatak kan erbjuda dig eller din arbetsplats?Kontakta oss så hjälper vi dig att förbättra din miljö.
                    </p>
                </div>
                <div className="imgWrapper">
                    <StaticImage src="../images/moreThanAView.jpg" placeholder="blurred"
                        layout="fixed"
                        height={450} />
                </div>
            </div>
            <div className="" id="takfonster"> 
                <h3>Virtuella Takfönster</h3>
                <p>Himlataks takfönster ger din lokal en unik känsla och kan få vilket rum som helst att kännas som att det ligger högst upp! Alla våra
                    fönster går att få med samtliga bilder. Ramarna går att få i svart och vitt med och utan spröjs. På vår sida <Link to="/bildgalleri/takfonster">bildgalleri</Link> går 
                    det att se alla bilder som går att välja mellan. Det är även möjligt att använda en egen bild. </p>
            </div>
            <div className="" id="fonster"> 
                <h3>Virtuella Fönster</h3>
                <p>Himlataks takfönster ger din lokal en unik känsla och kan få vilket rum som helst att kännas som att det ligger högst upp! Alla våra
                    fönster går att få med samtliga bilder. Ramarna går att få i svart och vitt med och utan spröjs. På vår sida <Link to="/bildgalleri/fonster">bildgalleri</Link> går 
                    det att se alla bilder som går att välja mellan. Det är även möjligt att använda en egen bild. </p>
            </div>

        </div>
    </Layout>
)
export default OmPage;